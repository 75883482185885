import React from 'react';

const Loader = ({ strokeColor = '#e15b64', width = 200, height = 200 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            width={width}
            height={height}
            style={{ shapeRendering: 'auto', display: 'block', background: 'transparent' }}
        >
            <g>
                <circle
                    strokeDasharray="164.93361431346415 56.97787143782138"
                    r="35"
                    strokeWidth="10"
                    stroke={strokeColor} // Use the strokeColor prop
                    fill="none"
                    cy="50"
                    cx="50"
                >
                    <animateTransform
                        keyTimes="0;1"
                        values="0 50 50;360 50 50"
                        dur="1s"
                        repeatCount="indefinite"
                        type="rotate"
                        attributeName="transform"
                    />
                </circle>
                <g></g>
            </g>
            {/* [ldio] generated by https://loading.io */}
        </svg>
    );
};

export default Loader;
